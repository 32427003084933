import React from 'react';

const AboutMe = () => {
    return (
        <section id="about-me" className="about-me-section bg-dark-blue" style={{ height: '30vh' }}>
    <h2 className="font-bold text-2xl mb-2">About Me</h2>
    {/* Add your content here */}
    <p>
      I'm a 4th-year student in Programvaresystemer, Datateknologi at NTNU. <br />
      I currently work as a learning assistant and recently completed a <br />
      12-week internship at DNV. <br />
      Beyond tech, I love travel, fitness, and exploring new cultures <br />
      through cooking and board games. I'm adaptable and a quick learner.
    </p>
</section>

    );
};

export default AboutMe;
