import React, { useState } from 'react';
import './Header.css'; 

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="header-container bg-dark-gray">
      <div className="logo-topleft">
        
        </div>
      <nav className="navigation">
        <a href="#about-me" className="nav-link">About</a>
        <a href="#skills" className="nav-link">Skills</a>
        <a href="#projects" className="nav-link">Projects</a>
        <a href="#experience" className="nav-link">Experience</a>
        <a href="#education" className="nav-link">Education</a>
        <a href="#socials" className="nav-link">Socials</a>
      </nav>

      {/* Language Selector */}
      <div className="language-selector " onClick={() => setShowDropdown(!showDropdown)}>
        <img src="../assets/svgs/linkedin.svg" alt="Language Selector" className=""/>
        
        {showDropdown && (
            <div className="language-dropdown">
            <div>Norwegian</div>
            <div>English</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
