import React from 'react';
import profilePic from '../assets/images/your-picture.png';

const Overview = () => {
    const scrollToAboutMe = () => {
        const aboutMeElement = document.getElementById('about-me');
        aboutMeElement.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <section id="overview" className="overview-section bg-dark-gray">
            <div className="flex items-center object-contain mt-4" style={{ height: '30vh' }} >
                {/* <img src={profilePic} alt="Your Name" className="profile-picture" style={{ height: '30vh' }} /> */}
              </div>
              <div className="flex items-center bg-dark-gray" >
              <div id="overview" className="text-center mt-4"> 
                <h1 className="font-bold text-2xl mb-2">Hello, I'm Hasan Omarzae</h1>
                <p className="text-gray-300">Currently taking my Msc in Software Systems at NTNU Trondheim</p>
              </div>
            </div>
            {/* Add this button to the bottom of your overview */}
            <button onClick={scrollToAboutMe} className="scroll-to-about-me-button">
                Learn more about me
            </button>
        </section>
    );
};

export default Overview;
