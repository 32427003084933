import React from 'react';
import educationData from '../assets/education.json';

const Education = () => {
  return (
    <div id="education" className="bg-dark-blue min-h-screen p-4 flex flex-col justify-center items-center"> {/* Changed flex to flex-col */}
  <div className="max-w-screen-lg mx-auto bg-dark-gray p-4 rounded-lg w-full">
    <h2 className="text-xl font-bold mb-4 text-center">Education</h2>
    <div className="grid grid-cols-1 md:grid-cols-1 gap-9">
      {educationData.map((edu) => (
        <div key={edu.degree} className="bg-dark-blue p-4 shadow-lg relative z-0 mb-4 mx-60">
          <h3 className="text-lg font-semibold mb-2">{edu.degree}</h3>
          <p className="mb-2">{edu.school}</p>
          <p>{edu.startDate} - {edu.endDate}</p>
          <p>{edu.gpa}</p>
          <p>{edu.courses}</p>
        </div>
      ))}
    </div>
  </div>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div>
  <a 
    href="mailto:hassano9@live.nl" 
    className="hover:text-white hover:shadow-lg hover:scale-110 transform transition duration-300 ease-in-out" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    hassano9@live.nl
  </a>
  <br />
  <a 
    href="tel:+4798454927" 
    className="hover:text-white hover:shadow-lg hover:scale-110 transform transition duration-300 ease-in-out"
  >
    +47 984 54 927
  </a>
</div>

</div>
  );
};

export default Education;


//TODO HAS TO APEAR ONE AFTER THE OTHER