import React from 'react';

const socials = [
  {
    name: 'GitHub',
    link: 'https://github.com/HasanOma',
    icon: 'github.svg'
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/in/hasan-omarzae-51747b249/',
    icon: 'linkedin.svg'
  }
  // ... add other social links as needed
];

const SocialLinks = () => {
  return (
    <div id="socials" className="flex space-x-5">
      {socials.map(social => (
        <a 
          key={social.name} 
          href={social.link} 
          target="_blank" 
          rel="noopener noreferrer"
          className="transform transition-transform hover:scale-110"
        >
          <img 
            src={require(`../assets/svgs/${social.icon}`)} 
            alt={social.name}
            className="w-6 h-6"
          />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
