const experienceData = [
  {
    "position": "Teaching Assistant",
    "company": "NTNU - Faculty of Information Technology and Electrical Engineering",
    "location": "Trondheim, NO",
    "startDate": "August 2021",
    "endDate": "Present",
    "description": "Various roles as a Teaching Assistant across multiple subjects.",
    "technologies": [
      "IDATT2502 Applied ML with project",
      "INFT2503, C++ for Programmers",
      "ISTT1003 Statistics",
      "IDATT2103 Databases",
      "IMAT2021 (Discrete) Mathematics",
      "IDATT1001 OOP in JAVA"
    ]
  },
  {
    "position": "Software Developer – Sesam GeniE",
    "company": "DNV – Det Norske Veritas",
    "location": "Høvik, NO",
    "startDate": "June 2023",
    "endDate": "August 2023",
    "description": "Porting and modernizing UI from MFC code to Qt for GeniE application.",
    "technologies": []
  },
  {
    "position": "Bachelor’s Thesis – Application to track ships",
    "company": "Accenture/NTNU - Faculty of Information Technology and Electrical Engineering",
    "location": "Trondheim, NO",
    "startDate": "January 2023",
    "endDate": "June 2023",
    "description": "Had responsibility for entire Backend structure including Database and third party API connection.",
    "technologies": []
  },
  {
    "position": "Software Engineering – Project to create a Borrowing application (finn.no)",
    "company": "IDATT2106 - System Development",
    "location": "Trondheim, NO",
    "startDate": "January 2021",
    "endDate": "May 2021",
    "description": "Lead the Backend and frontend team. Major implementations in frontend and backend.",
    "technologies": []
  }
];

export default experienceData;