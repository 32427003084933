import React, { useRef, useState, useEffect } from 'react';
import experienceData from '../assets/experienceData.js';

const throttle = (func, delay) => {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= delay) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, delay - (Date.now() - lastRan));
    }
  };
};

const Experience = () => {
  const [lineHeight, setLineHeight] = useState('0rem');
  const [triggerRerender, setTriggerRerender] = useState(0);  // Added this state
  const firstPointRef = useRef(null);
  const lastPointRef = useRef(null);

  const calculateHeight = () => {
    if (firstPointRef.current && lastPointRef.current) {
      const firstPointTop = firstPointRef.current.getBoundingClientRect().top;
      const lastPointBottom = lastPointRef.current.getBoundingClientRect().bottom;
      setLineHeight(`${lastPointBottom - firstPointTop}px`);
    }
  };

  useEffect(() => {
    calculateHeight();  // Recalculate height whenever triggerRerender changes
  }, [triggerRerender]);

  useEffect(() => {
    const throttledResize = throttle(() => setTriggerRerender(prevState => prevState + 1), 200);
    calculateHeight();
    window.addEventListener('resize', throttledResize);
    
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, []);

  return (
    <div className="bg-dark-gray relative w-full p-4">
      <h2 id="experience" className="text-xl font-bold mb-4">EXPERIENCE</h2>
      
      <div style={{ height: lineHeight, top: '4rem' }} className="absolute bg-gold left-1/2 transform -translate-x-1/2 w-1 bg-skyblue"></div>
      
      {experienceData.map((exp, index) => (
        <div key={exp.position} className="flex justify-center items-start mb-16">
          {index === 0 && <div ref={firstPointRef}></div>}
          
          {/* Experience Detail on the left */}
          {index % 2 === 0 ? 
            <>
              <ExperienceDetail exp={exp} side="left" triggerRerender={() => setTriggerRerender(prevState => prevState + 1)}  />
              <div className="w-4 h-4 bg-gold rounded-full mx-2 z-10"></div>
              <div className="w-1/4"></div>
            </> 
            : 
            <>
              <div className="w-1/4"></div>
              <div className="w-4 h-4 bg-gold rounded-full mx-2 z-10"></div>
              <ExperienceDetail exp={exp} side="right" triggerRerender={() => setTriggerRerender(prevState => prevState + 1)} />
            </> 
          }

          {index === experienceData.length - 1 && <div ref={lastPointRef}></div>}
        </div>
      ))}
    </div>
  );
};


const ExperienceDetail = ({ exp, side, triggerRerender }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const detailRef = useRef(null);

  useEffect(() => {
    triggerRerender();  // Call this whenever the component mounts/updates
  }, [isExpanded]);  // Re-render when isExpanded changes

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.5
      }
    );

    if (detailRef.current) {
      observer.observe(detailRef.current);
    }

    return () => {
      if (detailRef.current) {
        observer.unobserve(detailRef.current);
      }
    };
  }, []);

  return (
    <div 
      ref={detailRef}
      className={`flex-none w-1/4 bg-dark-blue p-4 shadow-lg relative z-0 transition-opacity duration-1000 transform transition-transform duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'} ${isVisible ? (side === 'left' ? 'translate-x-0' : 'translate-x-0') : (side === 'left' ? '-translate-x-12' : 'translate-x-12')}`}
    >
      {/* Positioning date range */}
      <p className={`text-xs date text-gray-300 absolute top-1 transform -translate-y-1 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} style={side === 'left' ? { left: '100%', marginLeft: '3rem' } : { right: '100%', marginRight: '3rem' }}>{exp.startDate} - {exp.endDate}</p>
      {/* Wrapper div */}
        <h3 className="font-bold">{exp.position}</h3>
        <p className="text-sm">{exp.company}</p>
        <p className="text-sm">{exp.location}</p>


        <div className={`relative overflow-hidden transition-max-height duration-1000 ease-in-out ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'}`}>
      
          {isExpanded && (
            <>
              <p>{exp.description}</p>
              <ul>
                {exp.technologies.map(tech => (
                <li key={tech}>{tech}</li>
                ))}
              </ul>
              <a href={exp.link} target="_blank" rel="noreferrer"></a>
            </>
          )}
      </div>

      <button onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? "-" : "+"}
      </button>
    </div>
  );
};

export default Experience;
