import React, { useState, useEffect, Suspense } from 'react';
import './App.css';

import Navigation from './components/Navigation';
import Header from './components/Header';
import Overview from './components/Overview';
import Skills from './components/Skills';
import AboutMe from './components/AboutMe'; // Import the new component
import Experience from './components/Experience';
import Education from './components/Education';
import Footer from './components/Footer';
const Projects = React.lazy(() => import('./components/Projects'));

function App() {
    const [showHamburger, setShowHamburger] = useState(false);
    const [isNavActive, setNavActive] = useState(false);

    const toggleNav = () => {
        setNavActive(prev => !prev);
    };

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 300) {
                setShowHamburger(true);
            } else {
                setShowHamburger(false);
                setNavActive(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="App">
            <Header showHamburger={showHamburger} />
            {/* {showHamburger && <button onClick={toggleNav}>&#9776;</button>} */}
            {/* <Navigation isActive={isNavActive} /> */}
            <Overview />
            <AboutMe /> {/* Place the new component under Overview */}
            <div className="bg-dark-gray">
            <Skills />
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <Projects />
            </Suspense>
            <Experience />
            <Education />
            <Footer />
        </div>
    );
}

export default App;
