import { useState, useEffect } from 'react';
import skillsData from '../assets/skillsData'; // Importing skillsData.js

const Skills = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const skillPos = document.getElementById('skills').getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (skillPos <= windowHeight) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div id="skills" className={`bg-dark-gray transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`} style={{ height: 'auto' }}>
  <h2 className="text-xl font-bold mb-4">Technical Skills</h2>
  <div className="flex flex-col justify-center items-center mt-4"> {/* Container for categories */}
    {Object.keys(skillsData).map((category, idx) => (
      <div key={idx} className="flex flex-col items-center mb-4 space-y-4"> {/* Each category */}
        <h3 className="text-lg font-semibold mb-4">{category}</h3>
        <div className="flex flex-wrap justify-center">
          {skillsData[category].map((skill, skillIdx) => (
            <div key={skillIdx} className="relative group m-2 hover:scale-110 transition duration-300 ease-in-out">
              <img src={skill.icon} alt={skill.name} className="w-8 h-8"/>
              <span className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-full group-hover:block hidden">
                {skill.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
</div>
  );
};

export default Skills;
