import React from 'react';
import SocialLinks from './SocialLinks';

const Footer = () => {
  return (
    <div className="bg-dark-gray">
    <footer className="text-white py-6 mt-12">
      <div className="container mx-auto flex justify-center">
        <SocialLinks />
      </div>
      <p className="text-center mt-4 text-xs">
        © {new Date().getFullYear()} Hasan Rehman Omarzae. All rights reserved.
      </p>
    </footer>
    </div>
  );
};

export default Footer;
